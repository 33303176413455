<template>
  <section class="project-house-funding">
    <section class="hero-small">
      <div>
        <h1>
          Financer sereinement la construction de sa maison
        </h1>
      </div>
    </section>
    <div class="grid-container">
      <div class="intro">
        <h3>
          Grâce à son concept novateur basé, entre autres, sur son configurateur en ligne et sa structure d’agence exclusive, Maisons Alysia propose au marché des prix de construction défiant la concurrence.
        </h3>
        <h6>
          Véritable levier à l’accès à la propriété, Maisons Alysia ne pouvait négliger le sujet du financement.  Trouver le bon financement, identifier votre capacité d’emprunt, quel type de prêt, sur quelle durée, c’est la vraie 1re étape de votre projet.
        </h6>
      </div>
      <div class="cards">
        <card-type
        title="Nos solutions de financement">
          <template v-slot:content>
            <p class="quote">« Notre ambition affichée : faire d’un locataire le propriétaire de sa résidence principale. »</p>
            <p>Votre Chargée de Projet Maisons Alysia réalise votre étude de financement gratuite, adaptée à votre profil, à vos revenus et intégrant tous les aides auxquelles vous pouvez prétendre. Il propose aux futurs acquéreurs la combinaison des meilleurs montages financiers en partenariat avec un réseau de courtiers indépendants visant à optimiser le rendement financier.</p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/funding.png" alt="" />
          </template>
        </card-type>
        <div class="long-frame">
          <div class="grid-x">
            <div class="cell small-12 medium-6 frame-illu">
              <img
                src="@/assets/img/pages/home-calculator.png"
                alt="Calculez votre capacité d'emprunt"
              />
            </div>
            <div class="cell small-12 medium-6 frame-content">
              <h2>
                Calculez votre capacité d'emprunt
              </h2>
              <p>
                Notre calculette de simulation de crédit vous permet d’estimer votre budget en 2 clics.
              </p>
              <!-- changer le name ci-dessous pour qu'il corresponde à : /outils/calculette-simulation-credit -->
              <router-link :to="{ name: 'calculator-budget' }">
                <app-button theme="secondary">Calculer maintenant</app-button>
              </router-link>
            </div>
          </div>
        </div>
        <card-type
        title="Toutes les aides au financement">
          <template v-slot:content>
            <p>Afin de favoriser l’accession à la propriété d’un maximum de ménages, le gouvernement a mis en place tout un arsenal de prêts immobiliers aidés. Le montage de votre financement personnalisé passe par l’analyse fine de votre profil et des aides dont vous pouvez bénéficier.</p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/funding-helps.png" alt="" />
          </template>
        </card-type>
        <card-type
        title="Notre partenariat de courtage en financement"
        hasBigText
        class="card-big-text">
          <template v-slot:content>
            <p class="quote">« Avec notre Courtier Partenaire, vous gagnez du temps, vous réduisez le coût de vos crédits. »</p>
          </template>
          <template v-slot:img>
            <div class="card-big-text-illu">
              <img class="character-image" src="@/assets/img/pages/character.svg" alt="" />
            </div>
          </template>
          <template v-slot:bigText>
            <p>
              Maisons Alysia a tissé un réseau de partenariat avec les courtiers les plus fiables et les plus compétents sur leur secteur. Chaque courtier est un véritable professionnel de l’intermédiation entre les banques/assureurs et l’emprunteur. Son activité est réglementée et son devoir de conseil est une obligation légale. <br/>
              Il est totalement indépendant, vous bénéficiez ainsi des meilleures conditions pour votre crédit immobilier par une mise en concurrence large et systématique et une capacité de négociation très performante au regard du volume qu’il traite. <br/><br/>
              Vous bénéficiez d’un accompagnement de A à Z avec une ingénierie personnalisée intégrant toutes le solutions possibles de crédits, prêts, aides, assurances…  Par sa connaissance du marché et des intervenants locaux, votre courtier est un expert financier de proximité qui détermine à coup sûr le montage optimal adapté à votre projet de construction. Au-delà du simple crédit, cet expert se rémunère auprès de ses partenaires financiers : pour vous c’est transparent, gratuit et tellement sécurisant.
            </p>
          </template>
        </card-type>
      </div>
    </div>
  </section>
</template>

<script>
import CardType from '@/components/general/CardType.vue';

export default {
  name: 'project-house-budget',
  components: {
    CardType,
  },
};

</script>

<style lang="sass" scoped>
.project-house-funding
  padding: 0 0 4rem
  background: $medium-bg
  max-width: 100vw
  overflow-x: hidden
  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, 0.6) 0%, rgba(32, 32, 32, 0.2) 100%), url(~@/assets/img/pages/house-funding.jpg)
    @media (max-width: 768px)
      background-position: 75%
  .intro
    margin: 6rem auto 5rem
    padding: 0 1rem
    max-width: 925px
    text-align: center
    h3
      margin-bottom: 26px
    @media (max-width: 768px)
      margin: 50px auto
  .cards
    .quote
      color: $primary
      margin-bottom: 24px
  .long-frame
    background: $primary
    border-radius: 8px
    color: $white
    padding: 72px 150px 72px 0
    margin-top: 48px
    margin-bottom: 48px
    .frame-illu
      display: flex
      img
        width: 90%
        margin: auto
    .frame-content
      display: flex
      flex-direction: column
      align-items: center
      padding: 0 50px
      box-sizing: border-box
      h2
        color: $white
        text-align: center
        margin-bottom: 16px
      p
        color: $white
        text-align: center
        margin-bottom: 16px
      a
        text-decoration: none
    @media (max-width: 768px)
      padding: 50px 0
      .frame-illu
        padding-bottom: 32px
  .card-big-text
    margin-top: 48px
    .card-big-text-illu
      background: url(~@/assets/img/pages/card-big-text-background-blue.png) no-repeat right bottom
      @media (max-width: 768px)
        width: 200px
        height: 200px
        border-radius: 1000px
        background: #25B7C6
        overflow: hidden
        margin: auto
      .character-image
        width: 130px
        margin-left: auto
        margin-right: 30px
        @media (max-width: 768px)
          margin: auto
          padding-top: 20px
  @media (max-width: 768px)
    .grid-container
      padding: 0 25px
</style>
